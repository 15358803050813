<template>
  <div class="policy">
    <div class="container">
      <h1 class="page-top">
        <div class="title">약관 및 정책</div>
      </h1>
      <div class="row">
        <aside class="col-12 col-lg-3">
          <Lnb/>
        </aside>
        <article class="col-12 col-lg-9">
          <div class="inner">
            <h2>증권형 이용약관</h2>
            <div class="section">
              <h3>제 1 조 목적</h3>
              <p>
                이 약관은 주식회사 오마이컴퍼니(이하 "회사"라 한다)와 회사가
                제공하는 증권형 크라우드펀딩 관련 서비스를 이용하려는 회원(이하
                “회원”이라 한다)간의 서비스 이용에 관한 권리의무 관련 사항을
                정함을 목적으로 한다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제 2 조 용어의 정의</h3>
              <p>이 약관에서 사용하는 용어의 정의는 아래와 같다.</p>
              <ul class="tight">
                <li>
                  ① 서비스 : 회원이 전자적 시스템에 접속하여 청약거래 등을 할 수
                  있게 회사가 이 약관에 따라 제공하는 제반 서비스를 말한다.
                </li>
                <li>
                  ② 홈페이지 : 이 약관에 따라 회사가 제공하는 서비스가 구현되는
                  온라인상의 웹페이지(http://www.ohmycompany.com)를 말한다.
                </li>
                <li>
                  ③ 발행인 : 회사와의 온라인소액투자중개계약을 체결하여
                  온라인소액투자중개의 방법으로 증권을 발행하려는 자를 말한다.
                </li>
                <li>
                  ④ 청약의 주문 : 회원이 발행인이 발행하는 증권을 배정받기
                  위하여 이 약관에 따른 방법으로 청약의 의사표시를 하는 것을
                  말한다.
                </li>
                <li>
                  ⑤ 청약의 철회 : 회원이 청약의 주문 후 청약기간 내에 청약의
                  의사표시를 철회하는 것을 말한다.
                </li>
                <li>
                  ⑥ 청약모집률 : 발행인이 목표한 모집예정금액을 기준으로 청약의
                  접수가 완료된 모집금액이 차지하는 비율을 말한다.
                </li>
                <li>
                  ⑦ 청약증거금관리기관 : 회원이 입금한 청약증거금을 자본시장과
                  금융투자업에 관한 법률(이하 “자본시장법”)에 따라 예치하여
                  보관하는 증권금융 또는 은행 등의 기관을 말한다.
                </li>
                <li>
                  ⑧ 중앙기록관리기관: 회사로부터 발행인과 투자자에 대한 정보를
                  제공받아 관리하는 기관인 한국예탁결제원을 말한다.
                </li>
                <li>
                  ⑨ 증권형 크라우드 펀딩 : 자본시장법에 따라
                  온라인소액투자중개업자를 통하여 발행인이 발행하는 채무증권,
                  지분증권, 투자계약증권에 관한 모집 또는 사모에 관한 중개행위를
                  말한다.
                </li>
                <li>
                  ⑩ 계좌관리기관 : 고객계좌를 관리하는 자로서 주식 · 사채 등의
                  전자등록에 관한 법률 제2조 제7호에서 정하는 자를 말한다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제3조 서비스 등의 제공</h3>
              <ul class="tight">
                <li>
                  <p>① 회사는 이 약관에 따라 아래와 같은 서비스를 제공한다.</p>
                  <ul class="list_depth1">
                    <li>
                      1. 청약서비스 : 청약회원 및 청약의 주문 정보 등에 대한
                      조회, 투자한도 조회, 청약의 주문 접수, 청약 가능 여부
                      통지, 청약결과 및 배정내역 통지 등
                    </li>
                    <li>
                      2. 제1호 서비스 제공을 위해 필요하거나 이에 부수하는
                      서비스
                    </li>
                  </ul>
                </li>
                <li>
                  ② 제1항의 서비스를 이용하고자 하는 회원은 회사의 회원약관을
                  통해 회원의 자격을 취득하고 유지하고 있어야 하며, 이 약관에
                  동의하여 체크하는 방법으로 서비스 이용신청을 하여야 한다.
                </li>
                <li>
                  ③ 청약의 주문은 24시간 가능하다. 다만 서비스이용제공의 시간에
                  변동이 있는 경우 사전에 홈페이지를 통하여 그 변동 사항을 미리
                  공지한다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제4조 서비스 수수료 등</h3>
              <p>
                회사는 온라인소액증권 청약과 관련하여 별도의 수수료는 징수하지
                아니한다. 다만 청약증거금 이체 시 이체수수료가 발생할 수 있다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제5조 투자정보의 게재 등</h3>
              <ul class="tight">
                <li>
                  ① 증권의 모집개시일 전에 홈페이지에 발행인이 게재하는 증권의
                  발행조건, 재무서류, 사업계획서 등 투자판단을 위해 필요한
                  정보가 게재된다.
                </li>
                <li>
                  ② 제1항에도 불구하고 청약기간 중에도 발행인이 홈페이지에
                  추가적으로 정보를 게재할 수 있으며, 홈페이지에 게재한 정보의
                  정정게재가 투자판단에 영향을 줄 수 있는 중요정보인 경우 그
                  게재가 청약기간의 말일로부터 7일 이내에 있게 된 때에는
                  그날로부터 7일 후로 청약기간이 연장된다.
                </li>
                <li>
                  ③ 청약기간이 종료한 후에도 제12조 제1항에 따라 모집결과가
                  “성공”인 경우에는 매년 1회 재무제표 등 결산 관련 서류가
                  홈페이지에 게재된다.
                </li>
                <li>
                  ④ 회사는 증권의 모집을 개시하기 전 본 조에 따라 게재된 정보에
                  관한 사실을 확인하여야 한다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제6조 청약의 주문 전 정보 확인 등</h3>
              <ul class="tight">
                <li>
                  ① 회원은 본 약관에 따른 청약의 주문 전에 제5조에 따라 제공되는
                  정보뿐만 아니라 청약기간, 증권의 배정조건, 증권매도의 제한
                  등에 관하여 충분히 숙지한 후 청약의 의사표시를 하여야 한다.
                </li>
                <li>
                  ② 발행인이 투자자의 유형별로 자격을 제한하거나 배정방법 및 그
                  기준을 다르게 설정하기를 회사에 요청하는 경우 회사는 그
                  배정방법과 기준이 합리적이고 명확한 기준에 의한 것인 경우 그
                  내용을 홈페이지에 게재하며, 이 경우 회원은 위 내용에 따라
                  증권의 배정을 받는데 제한을 받게 될 수 있다.
                </li>
                <li>
                  ③ 회사는 회원이 본 약관에 따른 청약의 주문을 하기 전에
                  회사로부터 청약대상이 되는 증권에 대한 투자의 위험고지 등에
                  대하여 회원이 충분히 확인을 하였는지를 전자서명이나 전자우편
                  등의 방법으로 확인한 후에 청약의 주문을 받을 수 있다.
                </li>
                <li>
                  ④ 회사는 발행인이 정정 게재를 하는 경우 자본시장법 등
                  관계법령이 정하는 바에 따라 회원에게 정정 게재 사실을 통지하고
                  회원의 청약 의사를 재확인한다. 또한 회사는 회원의
                  온라인소액투자중개의 위험성에 대한 충분한 이해도를 확인하고
                  청약을 받아야 하며, 회사는 회원이 재청약 의사를 표시하였는지를
                  확인하기 전에는 그 청약의 주문을 받을 수 없으며, 이 경우
                  회원의 기존 청약의 주문 신청은 취소된다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제7조 의견교환 게시판의 운영·관리 등</h3>
              <ul class="tight">
                <li>
                  ① 회사는 투자정보에 관한 투자의견을 교환할 수 있는 게시판을
                  운영한다.
                </li>
                <li>
                  ② 회사는 자신의 홈페이지를 통하여 공개되는 투자자들의 의견을
                  임의로 삭제하거나 수정하여서는 아니 된다. 다만, 다른 법률에
                  근거가 있는 경우 그러하지 아니하다.
                </li>
                <li>
                  ③ 발행인은 청약기간이 종료되기 7일 전까지 홈페이지에 개설된
                  게시판을 통해 회원들에게 투자정보를 제공할 수 있다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제8조 청약의 주문 방법</h3>
              <ul class="tight">
                <li>
                  <p>
                    ① 회원이 배정받고자 하는 증권에 대하여 아래와 같은 정보를
                    회사가 제공하는 양식에 따라 입력하는 방법으로 청약의 주문을
                    하여야 한다.
                  </p>
                  <ul class="list_depth1">
                    <li>1. 회원의 실명(법인인 경우 상호 및 명칭)</li>
                    <li>
                      2. 주민등록번호(법인인 경우 사업자등록번호, 법인등록번호,
                      외국인인 경우 그 국적과 투자등록번호 또는 실지명의번호,
                      국내거소신고번호)
                    </li>
                    <li>3. 투자자의 유형</li>
                    <li>
                      4. 그 밖에 자본시장법 등 관계법령에 따라
                      온라인소액투자중개에 필요한 투자자 정보
                    </li>
                  </ul>
                </li>
                <li>
                  ② 회사는 투자자의 실명인증 및 투자자 본인과의 동일성 확인을
                  위한 인증절차를 거쳐야 한다.
                </li>
                <li>
                  ③ 회사는 회원이 청약신청 후 청약증거금을 회원 명의의 계좌에서
                  청약증거금 관리기관에 개설된 회사 명의의 청약증거금 관리계좌에
                  직접 이체하도록 하여야 한다.
                </li>
                <li>
                  ④ 회원은 회원정보에서 정한 투자자의 유형과 다른 유형의
                  자격에서 투자를 하려는 경우 회원정보에서 투자자의 유형을
                  사전에 변경하여야 한다.
                </li>
                <li>
                  ⑤ 회원은 일반투자자가 아닌 경우(소득적격투자자, 전문투자자)에
                  이에 관한 증빙자료(소득요건 등에 관한 자료, 법인등기부등본
                  등)를 제출한 후에 청약의 신청이 가능하다.
                </li>
                <li>
                  ⑥ 회원은 자본시장법 등 관계법령에서 정한 투자자의 투자한도를
                  준수하여 청약의 주문을 하여야 한다.
                </li>
                <li>
                  ⑦ 회원은 청약의 주문을 한 후에 본 조에 따라 입력한 정보에
                  변동사항이 있는 경우에는 청약기간이 종료되기 전에 한하여
                  회사에 변경을 요청할 수 있다.
                </li>
                <li>
                  ⑧ 제2항에 따라 투자자가 제출한 정보는 자본시장법 등 관계법령에
                  따라 중앙기록관리기관에 제공된다.
                </li>
                <li>
                  ⑨ 회사는 청약자의 재산인 청약증거금을 은행 또는 증권금융회사에
                  예치 또는 신탁되도록 하며, 예치 또는 신탁된 청약증거금을
                  양도하거나 담보로 제공하지 않는다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제9조 청약의 주문 접수</h3>
              <ul class="tight">
                <li>
                  ① 회원은 제8조에 따라 입력한 정보에 관하여 확인절차를 모두
                  마친 후“청약의 주문”을 클릭하면 청약의 주문이 회사에 신청되며,
                  회사는 그 외의 방법으로 임의로 청약의 주문 접수를 받지 않는다.
                </li>
                <li>
                  ② 제1항의 청약의 주문 신청에 대하여 회사가 접수를 완료하면
                  청약의 주문 접수가 완료된다.
                </li>
                <li>
                  ③ 회사는 발행인이 합리적이고 명확한 기준에 따라 투자자의 자격
                  제한을 요청한 경우 그에 따라 청약의 주문 신청을 제한하여
                  접수할 수 있다.
                </li>
                <li>
                  ④ 회사는 청약의 주문 신청 결과를 전자우편 또는 문자 등 회원과
                  합의한 방법을 통해 통보한다.
                </li>
                <li>
                  ⑤ 청약의 주문이 접수되면 홈페이지에 청약모집률이 게시된다.
                  다만, 본 약관에 따라 청약의 철회가 있거나 청약의 주문이 효력이
                  상실되는 경우에는 청약모집률은 변동될 수 있다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제10조 청약의 주문 취소 등</h3>
              <ul class="tight">
                <li>
                  ① 제8조에 따른 확인에도 불구하고 회원이 입력한 정보 또는
                  이행하여야 하는 절차의 전부 또는 일부(일반투자자 외 투자자
                  요건, 증권의 계좌유효성 등)가 추후 허위 또는 중대하게 누락된
                  것으로 판명되거나 입증이 이루어지지 아니하는 경우(이하 “청약의
                  주문 취소사유”)에는 청약의 주문 신청이 취소되어 효력을 상실할
                  수 있다.
                </li>
                <li>
                  ② 회사는 필요한 경우 제1항에 따른 확인절차를 이행하기 위하여
                  자본시장법 등 관계법령이 허용하는 한도 내에서 중앙기록관리기관
                  등에 정보조회를 의뢰할 수 있다.
                </li>
                <li>
                  ③ 회원이 청약의 주문을 완료한 후 회사의 회원약관에 따른 회원의
                  자격을 상실한 경우에도 해당 청약의 주문 건에 대하여는 청약의
                  주문이 유효한 것으로 본다.
                </li>
                <li>
                  ④ 증권의 모집이 개시된 이후에도 회사와 발행인과의
                  온라인소액투자중개계약 해지 등 관계법령이 정하는 사유로 인하여
                  증권의 발행이 취소될 수 있다. 이 경우 회원의 청약주문 신청의
                  효력은 상실된다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제11조 청약의 철회</h3>
              <ul class="tight">
                <li>
                  ① 회원은 청약의 주문 접수 후 청약기간의 종료 전(청약기간이
                  연장된 경우 그 변경된 청약기간의 종료 전)까지 회사에 청약의
                  철회 신청을 하여 청약의 의사표시를 철회할 수 있다.
                </li>
                <li>
                  ② 제1항에 따라 회원이 유효하게 청약의 철회를 한 경우 입금한
                  청약금은 청약철회신청이 회사에게 도달한 날로부터 지체 없이
                  회원의 계좌로 반환된다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제12조 모집결과의 게시 및 통보 등</h3>
              <ul class="tight">
                <li>
                  <p>
                    ① 회사는 청약기간이 종료하면 모집결과를 아래와 같은 기준에
                    따라 “성공” 또는 “실패”로 구분하여 홈페이지에 게시한다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. “성공” : 모집된 청약금액이 발행인이 목표한 모집금액의
                      80% 이상인 경우
                    </li>
                    <li>
                      2. “실패” : 모집된 청약금액이 발행인이 목표한 모집금액의
                      80% 미만인 경우
                    </li>
                  </ul>
                </li>
                <li>
                  ② 제1항 제1호에 따라 “성공”한 경우에도 회원은 홈페이지에
                  사전에 게시된 증권의 발행조건 또는 배정조건에 따라 최종적으로
                  증권의 배정을 받지 못하게 될 수 있다.
                </li>
                <li>
                  <p>
                    ③ 회사는 청약기간 만료시 대상증권의 청약 및 발행에 관한
                    내역을 홈페이지에 게시하고 전자우편 등을 통해 투자자에게
                    통지하여야 한다. 통지하는 내역은 다음 각 호와 같다.
                  </p>
                  <ul class="list_depth1">
                    <li>
                      1. 전체 투자자의 청약금액, 청약수량, 청약 증거금 및 그밖에
                      증권의 취득에 관한 청약의 세부사항
                    </li>
                    <li>
                      2. 전체 투자자의 청약금액이 모집예정금액의 80% 이상인지
                      여부, 전체 투자자로부터 발행인에게 실제 납입될 증권 대금,
                      그 밖에 증권의 발행에 관한 세부사항
                    </li>
                    <li>
                      3. 투자자에게 배정된 증권의 가액 및 수량, 납입기일, 그
                      밖에 증권 배정 및 납입에 관한 사항
                    </li>
                    <li>
                      4. 회사가 청약증거금을 반환하여야 할 경우 그 금액 및
                      반환일정 등 반환에 관한 사항
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제13조 목표모집금액의 변경</h3>
              <ul class="tight">
                <li>
                  ① 청약된 금액이 모집예정금액을 초과하였을 때에는 발행인의
                  의사에 따라 당초 목표한 모집금액이 증액될 수 있으며, 이 경우
                  제5조에 따라 홈페이지에 게재된 정보의 변경이 있게 되면 변경된
                  사항은 홈페이지에 정정 게재된다.
                </li>
                <li>
                  ② 제1항에 따라 모집가액이 증액되어 변경됨에 따라 자본시장법 등
                  관계법령에서 발행인에게 요구하는 회계감사의 단계가 변경되는
                  경우에는 증액된 금액에 상응하는 회계감사의 기준에 따라 정정된
                  정보가 게재된다.
                </li>
                <li>
                  ③ 청약기간의 종료일로부터 7일 이내에 발행인이 정정한
                  중요게재정보(모집자금의 사용목적 등)에 대하여 제10조에 따른
                  회사의 사실 확인이 추가로 필요하게 된 경우에는 청약기간의
                  만료일은 정정게재일로부터 7일의 범위 내에서 연기될 수 있다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제14조 청약금의 납입 및 반환</h3>
              <ul class="tight">
                <li>
                  ① 제12조 제1항 제1호에 따라 모집결과가 “성공”한 경우
                  청약증거금관리기관에 예치되어 있던 청약금 전액이 발행인 명의
                  납입계좌로 납입된다.
                </li>
                <li>
                  ② 제12조 제1항 제2호에 따라 모집결과가 “실패”한 경우 회원이
                  입금한 청약증거금은 청약기간이 종료한 날로부터 지체 없이
                  회원의 계좌로 반환된다.
                </li>
                <li>
                  ③ 제10조, 제11조에 따라 청약의 주문이 취소(발행이 취소되는
                  경우 포함)되거나 회원이 청약을 철회하는 경우 회원이 입금한
                  청약증거금은 위와 같은 사유가 발생한 날로부터 지체 없이 회원의
                  계좌로 반환된다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제15조 증권의 발행 및 예탁 등</h3>
              <ul class="tight">
                <li>
                  ① 제14조 제1항에 따라 청약금이 납입되면 사전에 게재된
                  발행조건(변경된 발행조건 포함)에 따라 증권이 발행되어 증권의
                  배정을 받은 회원 명의가 투자자명부에 등록됨으로써
                  중앙기록관리기관에 배정받은 증권이 예탁되거나 계좌관리기관에
                  개설된 회원의 고객계좌에 수량이 기재됨으로써 전자등록된다.
                </li>
                <li>
                  ② 제1항에 따라 발행된 증권은 의무적으로 중앙기록관리기관에
                  예탁 또는 의무보유등록되거나 보호예수되며, 회원은 배정받은
                  증권을 그 예탁일 또는 보호예수일로부터 6개월간 매도, 그 밖의
                  방법으로 양도할 수 없다. 다만, 관계법령에 따라 예외적으로
                  매도가 가능한 경우로 인정되는 경우에는 그러하지 아니하다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제16조 권리의 귀속 등</h3>
              <p>
                회사가 제공하는 서비스(데이터, 소프트웨어, 게시물의 저작권 등)에
                관한 권리는 회사에게 귀속된다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제17조 불가항력에 따른 면책</h3>
              <p>
                회사는 천재지변, 전시·사변 또는 이에 준하는 불가항력이라고
                인정되는 사유로 인하여 회원에게 발생하는 손해에 대하여 책임을
                지지 아니한다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제18조 약관의 변경 등</h3>
              <ul class="tight">
                <li>
                  ① 회사는 이 약관을 변경하고자 하는 경우 변경내용을 변경되는
                  약관의 시행일 1개월 전에 회원이 확인할 수 있도록 인터넷
                  홈페이지 그 밖에 이와 유사한 전자통신매체를 통하여 게시한다.
                  다만, 자본시장법 등 관계법령의 제·개정에 따른 제도변경 등으로
                  약관이 변경되는 경우로서 본문에 따라 안내하기가 어려운
                  급박하고 부득이한 사정이 있는 경우에는 변경내용을 앞의 문장과
                  같은 방법으로 개정 약관의 시행일 전에 게시한다.
                </li>
                <li>
                  ② 회사는 제1항의 변경내용이 회원에게 불리한 것일 때에는 이를
                  서면 등 회원과 사전에 합의한 방법으로 변경되는 약관의 시행일
                  1개월 전까지 통지하여야 한다. 다만, 기존 회원에게 변경 전
                  내용이 그대로 적용되는 경우 또는 회원이 변경내용에 대한 통지를
                  받지 아니하겠다는 의사를 명시적으로 표시한 경우에는 그러하지
                  아니하다.
                </li>
                <li>
                  ③ 회사는 제2항의 통지를 할 경우 "회원은 약관의 변경에 동의하지
                  아니하는 경우 계약을 해지할 수 있으며, 통지를 받은 날로부터
                  변경되는 약관의 시행일 전의 영업일까지 계약해지의 의사표시를
                  하지 아니한 경우에는 변경에 동의한 것으로 본다"라는 취지의
                  내용을 통지하여야 한다.
                </li>
                <li>
                  ④ 회원이 제3항의 통지를 받은 날로부터 변경되는 약관의 시행일
                  전의 영업일까지 계약해지의 의사표시를 하지 아니하는 경우에는
                  변경에 동의한 것으로 본다.
                </li>
                <li>
                  ⑤ 회사는 이 약관을 회사의 영업점에 회원이 확인할 수 있도록
                  마련해 두거나 게시하여 회원이 요구할 경우 이를 교부하여야
                  하며, 인터넷 홈페이지 그 밖에 이와 유사한 전자통신매체에
                  게시하여 회원이 약관을 확인하고 다운로드(화면출력 포함)받을 수
                  있도록 하여야 한다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제19조 관계법규 등 준수</h3>
              <p>
                회원과 회사는 자본시장법, 같은 법 시행령 및 시행규칙, 관련
                금융위원회의 고시, 한국금융투자협회 업무규정, 한국거래소
                업무규정 등 관계규정을 준수한다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제20조 관계법규 등 준용</h3>
              <ul class="tight">
                <li>
                  ① 이 약관에서 정하지 아니한 사항에 대해서는 별도의 약정이 없는
                  한 관계법규등에서 정하는 바에 따른다. 다만, 관계법규 등에도
                  정하는 바가 없으면 일반적인 상관례를 따른다.
                </li>
                <li>
                  ② 이 약관에 의한 거래 중 전자금융거래에 관하여는
                  ‘전자금융거래이용에 관한 기본약관’ 및 전자금융거래법령이 우선
                  적용된다.
                </li>
              </ul>
            </div>
            <div class="section">
              <br/>
              <h3>제21조 분쟁조정</h3>
              <p>
                회원은 회사와 분쟁이 발생하는 경우 회사의 민원처리기구에 그
                해결을 요구하거나 금융감독원, 한국금융투자협회 등에 분쟁조정을
                신청할 수 있다.
              </p>
            </div>
            <div class="section">
              <br/>
              <h3>제22조 관할법원</h3>
              <p>
                이 약관에 의한 거래와 관련하여 발생된 분쟁에 대하여 회사와 회원
                사이에 소송의 필요가 생긴 경우에는 그 관할법원은
                「민사소송법」이 정한 바에 따른다.
              </p>
            </div>
            <div class="section">
              <br/>
              <p>
                &lt;시행일&gt;<br/>
                본 약관은 2020년 5월 20일부터 시행한다.
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import Lnb from "./Lnb.vue";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pagePolicyInvestTerms";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {
    Lnb,
  },
  setup() {
    const component = new Component(() => {
    });

    return {component};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.policy";
</style>